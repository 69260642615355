<script lang="ts">import ButtonGroup from "./ButtonGroup.svelte";
export let helpers;
export let zIndex = 1;
</script>

<div class="-helper-wrapper flex-fill">
	<div class="-helper-wrapper-content">
		<slot />
	</div>
	<div class="-helper-wrapper-helpers" style="z-index: {zIndex};">
		<ButtonGroup noBgAndBorder min_to_dropdown={false} buttons={helpers} />
	</div>
</div>

<style lang="scss" global>:global(.-helper-wrapper) {
  position: relative;
  width: 100%;
}
:global(.-helper-wrapper) :global(.-helper-wrapper-content) {
  width: 100%;
}
:global(.-helper-wrapper) :global(.-helper-wrapper-helpers) {
  position: absolute;
  top: 0;
  right: 0;
}</style>
